import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { IFetchGeneratePreviewRequestBody, IGeneratedPreview, previewAtom, previewsLoadingAtom } from "./types";

type FetchGeneratedPreviewType = (body: IFetchGeneratePreviewRequestBody) => Promise<IGeneratedPreview | void>;

type GeneratedPreviewsKeyFunctions = {
    fetchPreviews: FetchGeneratedPreviewType;
}

type UsePreviews = [
    { [key: number] : IGeneratedPreview },
    GeneratedPreviewsKeyFunctions
];

export const useGeneratedPreviews = (): UsePreviews => {
    const [previews, setPreviews] = useAtom(previewAtom);
    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setPreviewsLoading = useSetAtom(previewsLoadingAtom);

    const fetchGeneratedPreview = async (body: IFetchGeneratePreviewRequestBody): Promise<IGeneratedPreview | void> => {
        setPreviewsLoading(true);
        return await Axios.post(environment.generatePreviewUrl, body, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
            .then((result: AxiosResponse<IGeneratedPreview>) => {
                const previewData = { ...previews }
                if (result.data && result.data.mockupId) {
                    previewData[result.data.mockupId] = result.data;
                    setPreviews(previewData);
                    return result.data;
                } else {
                    previewData[body.mockupId] = { error: result } as any;
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'error',
                        text: 'There was an error while fetching the generated previews. Please try again later.'
                    })
                    setPreviews(previewData);
                }
            })
            .catch((err: any) => errorCatcher(err, loginFunctions.logout))
            .finally(() => setPreviewsLoading(false));
    }

    const keyFunctions: GeneratedPreviewsKeyFunctions = {
        fetchPreviews: fetchGeneratedPreview
    }

    return [
        previews,
        keyFunctions
    ]
}

