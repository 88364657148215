import { atom } from "jotai";
import { ChannelType, PaginatedResponseType } from "../../globals/types";

export const mockupsAtom = atom<PaginatedResponseType<IMockup>>({ results: [], count: 0, hasNext: false, hasBefore: false, channel: ChannelType.WALLMATES });
export const mockupsLoadingAtom = atom<boolean>(false);

export const previewAtom = atom<{ [key: number]: IGeneratedPreview}>({});

export const previewsLoadingAtom = atom<boolean>(false);

export interface IMockup {
    id?: number;
    s3_path: string;
    thumbnail: string;
    mockup_name: string;
    room_type_id: number;
    style_type_id: number;
    color_type_id: number;
}

export interface IMockupsFilter {
    style_type_id?: string;
    color_type_id?: string;
    room_type_id?: string;
    verified?: boolean;
    query_string?: string;
}

export interface IUpdateMockupRequest {
    id: number;
    mockup_file?: File;
    mockup_name: string;
    room_type_id: number;
    style_type_id: number;
    color_type_id: number;
}

export interface IGetMockupsRequest {
    page_count: number;
    page: number;
    filter_by: IMockupsFilter;
}

export interface IFetchGeneratePreviewRequestBody {
    patternScale?: string;
    previewWidth?: number;
    shadowOpacity?: number;
    enhanceShadows?: boolean;
    previewQuality?: number;
    mockupId: number;
    productId: number;
}

export interface IGeneratedPreview {
    mockupId: number;
    productId: number;
    preview: string;
}